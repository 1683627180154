
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["shiftType", "insideDatetimeRange", "outsideDatetimeRange"]

  static values = {
    shiftType: String
  }

  connect(){
    this.loadShiftTypeVisibleOption()
  }

  loadShiftTypeVisibleOption() {
    if( this.shiftTypeValue == "hospital_inside" ) {
      $(this.insideDatetimeRangeTarget).show()
      $(this.outsideDatetimeRangeTarget).hide()
      console.log($(this.outsideDatetimeRange).find("input").val(""))
    }
    else {
      $(this.outsideDatetimeRangeTarget).show()
      $(this.insideDatetimeRangeTarget).hide()
      $(this.insideDatetimeRangeTarget).find("input").val("")
      $(this.insideDatetimeRangeTarget).find("select").val("")
    }
  }

  selectShiftType(e){
    this.shiftTypeValue = e.target.value
  }

  shiftTypeValueChanged(){
    this.loadShiftTypeVisibleOption()
  }
}
