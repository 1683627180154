
$(document).on("turbo:load", function () {

    $('#sidebarCollapse').on('click', function () {
        let sidebarCollapse = $("#sidebarCollapse")
        $('#sidebar').toggleClass('active');
        $(".content").toggleClass("active")
        if((sidebarCollapse).hasClass("bi-text-indent-left")){
            sidebarCollapse.removeClass("bi-text-indent-left")
            sidebarCollapse.addClass("bi-text-indent-right")
        } else {
            sidebarCollapse.removeClass("bi-text-indent-right")
            sidebarCollapse.addClass("bi-text-indent-left")
        }
        console.log(111)
    });

});