// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

let vh = (document.documentElement.clientHeight || window.innerHeight) * 0.01
console.log(vh)
document.documentElement.style.setProperty("--vh", `${vh}px`)

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'bootstrap/dist/js/bootstrap'
import '../styles/application.scss'
import 'bootstrap-icons/font/bootstrap-icons.css'
import './sidebar'

Rails.start()
ActiveStorage.start()

import "controllers"

